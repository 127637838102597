import { Type } from '@angular/core';

export const modalRegistry: Record<string, () => Promise<Type<any>>> = {
  AiDescriptionModal: () =>
    import(
      '../components/ai-description-modal/ai-description-modal.component'
    ).then((m) => m.AiDescriptionModalComponent),
  MapRedirectModal: () =>
    import('../../user/map-redirect-modal/map-redirect-modal.component').then(
      (m) => m.MapRedirectModalComponent
    ),
  SubscriptionConfirmationModal: () =>
    import(
      '../../user/user/subscriptions-menu/subscription-confirmation-modal/subscription-confirmation-modal.component'
    ).then((m) => m.SubscriptionConfirmationModalComponent),
  SubscriptionRenewalModal: () =>
    import(
      '../../user/user/subscriptions-menu/subscription-confirmation-modal/subscription-renewal-modal/subscription-renewal-modal.component'
    ).then((m) => m.SubscriptionRenewalModalComponent),
  SwitchLandscapeModal: () =>
    import(
      '../components/switch-to-portrait-modal/switch-to-portrait.component'
    ).then((m) => m.SwitchToPortraitComponent),
  FavoriteCellPersonalizationModal: () =>
    import(
      '../../map/favorites/favorite-personalization-modal/favorite-personalization-modal.component'
    ).then((m) => m.FavoritePersonalizationModalComponent),
};
