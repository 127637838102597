import { inject, Injectable } from '@angular/core';
import { MapboxOptions } from 'mapbox-gl';
import { BehaviorSubject, combineLatestWith, debounceTime } from 'rxjs';
import {
  crimeRatesList,
  MEDIAN_INCOME,
  voteDataFeatures,
} from '../../../shared/types/feature-data-type';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../../shared/services/modal.service';
import { dataIsNotAvailableForLevelText } from '../../../user/map-redirect-modal/map-redirect-modal-text';
import { pointsMinZoom } from '../../../shared/util/consts';

export const POIS_LAYER = 'POIS_LAYER';
const TILES_PATH = 'tiles/';
export const H3_RES7_LEVEL_LAYER = 'featured_cell_h3_res_7';
export const H3_RES5_LEVEL_LAYER = 'featured_cell_h3_res_5';
export const STATE_LEVEL_LAYER = 'featured_cell_state';
export const COUNTY_LEVEL_LAYER = 'featured_cell_county';

const OSM_PATH = 'tiles-osm/osm/';
const OSM_PLACES = 'osm_places';
const OSM_HOUSENUMBERS = 'osm_housenumbers';
const OSM_BUILDINGS = 'osm_buildings';

export const ORI_LAYER = 'ori';
export const ORI_ICON_LAYER = 'ori_icon';
export const PUBLIC_SCHOOLS_LAYER = 'nces';
export const PRIVATE_SCHOOLS_LAYER = 'pss';

export const ARBITRARY_CELL = 'ARBITRARY';
export const MIN_RES_7_ZOOM = 8;

export const shortLevelNameToTile = new Map<string, string>([
  ['STATE', STATE_LEVEL_LAYER],
  ['COUNTY', COUNTY_LEVEL_LAYER],
  ['H3_RES_5', H3_RES5_LEVEL_LAYER],
  ['H3_RES_7', H3_RES7_LEVEL_LAYER],
]);

export interface LayerData {
  subdir: string;
  type: string;
  minzoom: number;
  maxzoom: number;
}

export interface SourceDataStore {
  [key: string]: {
    [key: string]: LayerData;
  };
}

enum LevelLayerComboCorrectionStages {
  NO_INTERACTIONS = 'NO_INTERACTIONS',
  NOTIFICATION_SHOWN = 'NOTIFICATION_SHOWN',
  MODAL_SHOWN = 'MODAL_SHOWN',
}

export const MAP_STYLE = 'MAP_STYLE';
export const STYLE_LIGHT = `mapbox://styles/mapbox/light-v11`;
export const STYLE_STREETS = 'mapbox://styles/mapbox/streets-v12';

export type MapStyle = typeof STYLE_LIGHT | typeof STYLE_STREETS;

@Injectable({
  providedIn: 'root',
})
export class LayerStoreService {
  private toast = inject(ToastrService);
  private modalService = inject(ModalService);

  public readonly mapOptions: MapboxOptions = {
    container: 'map',
    style:
      localStorage.getItem(MAP_STYLE) === STYLE_LIGHT
        ? STYLE_LIGHT
        : STYLE_STREETS,
    zoom: 3,
    // Zoom is limited by highest value that doesn't lead to render bugs
    maxZoom: 15.8,
    center: [-96, 37],
    projection: { name: 'mercator' },
  };

  public currentZoom!: number;

  public readonly sourceDataStore: SourceDataStore = {
    levels: {
      state: {
        subdir: TILES_PATH + STATE_LEVEL_LAYER,
        type: STATE_LEVEL_LAYER,
        minzoom: 0,
        maxzoom: 15,
      },
      county: {
        subdir: TILES_PATH + COUNTY_LEVEL_LAYER,
        type: COUNTY_LEVEL_LAYER,
        minzoom: 0,
        maxzoom: 15,
      },
      h3_res5: {
        subdir: TILES_PATH + H3_RES5_LEVEL_LAYER,
        type: H3_RES5_LEVEL_LAYER,
        minzoom: 0,
        maxzoom: 15,
      },
      h3_res7: {
        subdir: TILES_PATH + H3_RES7_LEVEL_LAYER,
        type: H3_RES7_LEVEL_LAYER,
        minzoom: 0,
        maxzoom: 15,
      },
    },
    osm: {
      housenumbers: {
        subdir: OSM_PATH + OSM_HOUSENUMBERS,
        type: OSM_HOUSENUMBERS,
        minzoom: 14,
        maxzoom: 24,
      },
      places: {
        subdir: OSM_PATH + OSM_PLACES,
        type: OSM_PLACES,
        minzoom: 14,
        maxzoom: 24,
      },
      buildings: {
        subdir: OSM_PATH + OSM_BUILDINGS,
        type: OSM_BUILDINGS,
        minzoom: 14,
        maxzoom: 24,
      },
    },
    crime: {
      ori: {
        subdir: TILES_PATH + ORI_LAYER,
        type: ORI_LAYER,
        minzoom: pointsMinZoom,
        maxzoom: 24,
      },
    },
    schools: {
      public: {
        subdir: TILES_PATH + PUBLIC_SCHOOLS_LAYER,
        type: PUBLIC_SCHOOLS_LAYER,
        minzoom: pointsMinZoom,
        maxzoom: 24,
      },
      private: {
        subdir: TILES_PATH + PRIVATE_SCHOOLS_LAYER,
        type: PRIVATE_SCHOOLS_LAYER,
        minzoom: pointsMinZoom,
        maxzoom: 24,
      },
    },
  };

  public readonly layersLevelList = [
    STATE_LEVEL_LAYER,
    COUNTY_LEVEL_LAYER,
    H3_RES5_LEVEL_LAYER,
    H3_RES7_LEVEL_LAYER,
  ];

  public readonly OSMLayerList = [OSM_HOUSENUMBERS, OSM_PLACES, OSM_BUILDINGS];

  private DEFAULT_LEVEL = STATE_LEVEL_LAYER;

  public readonly activeLevel = new BehaviorSubject<string>(this.DEFAULT_LEVEL);

  public previousActiveLevel: string | null = null;

  public readonly DEFAULT_LAYER = MEDIAN_INCOME;

  public readonly activeLayer = new BehaviorSubject<string>(this.DEFAULT_LAYER);

  public previousActiveLayer: string | null = null;

  public readonly defaultLocation = {
    center: [-96, 37],
    zoom: 3,
  };

  //level-selection tool state
  public isLevelLayerSelected: boolean = false;
  public lastSelectedLevel: string | null = null;

  public isArbitraryCellUsed: boolean = false;

  // Flag says if we already changed level because of inaccessible combination from isLayerLevelCombinationUnavailable()
  private currentLayerForceChangeStage: LevelLayerComboCorrectionStages =
    LevelLayerComboCorrectionStages.NO_INTERACTIONS;

  constructor() {
    this.handleLayerLevelSubscription();
  }

  public onZoomSwitchLayer(zoom: number, isMobile: boolean) {
    const currentActiveLevel = this.activeLevel.getValue();
    const newActiveLevel = this.getActiveLevel(
      zoom,
      this.isLevelLayerSelected,
      isMobile
    );

    // Only update the active level if it has changed
    if (currentActiveLevel !== newActiveLevel) {
      this.handleLevelChange(newActiveLevel);
    }

    this.currentZoom = zoom;
  }

  private getActiveLevel(
    zoom: number,
    isLevelLayerSelected: boolean,
    isMobile: boolean
  ): string {
    if (isMobile) {
      if (zoom < 4) {
        return STATE_LEVEL_LAYER;
      } else if (zoom < 6) {
        return COUNTY_LEVEL_LAYER;
      } else if (zoom < 9) {
        return H3_RES5_LEVEL_LAYER;
      } else {
        return H3_RES7_LEVEL_LAYER;
      }
    }

    if (isLevelLayerSelected) {
      if (this.lastSelectedLevel === STATE_LEVEL_LAYER && zoom < 8) {
        return STATE_LEVEL_LAYER;
      } else if (this.lastSelectedLevel === COUNTY_LEVEL_LAYER && zoom < 11) {
        return COUNTY_LEVEL_LAYER;
      } else if (
        this.lastSelectedLevel === H3_RES7_LEVEL_LAYER ||
        this.lastSelectedLevel === H3_RES5_LEVEL_LAYER
      ) {
        if (zoom >= MIN_RES_7_ZOOM) {
          return H3_RES7_LEVEL_LAYER;
        } else {
          return H3_RES5_LEVEL_LAYER;
        }
      }
    }

    if (this.currentZoom < zoom) {
      if (
        zoom > 5 &&
        zoom < 7 &&
        this.activeLevel.getValue() !== H3_RES5_LEVEL_LAYER
      ) {
        return COUNTY_LEVEL_LAYER;
      } else if (zoom >= 7 && zoom < MIN_RES_7_ZOOM) {
        return H3_RES5_LEVEL_LAYER;
      } else if (zoom >= MIN_RES_7_ZOOM) {
        return H3_RES7_LEVEL_LAYER;
      }
    } else if (
      this.activeLevel.getValue() === H3_RES7_LEVEL_LAYER ||
      this.activeLevel.getValue() === H3_RES5_LEVEL_LAYER
    ) {
      if (zoom < MIN_RES_7_ZOOM) {
        return H3_RES5_LEVEL_LAYER;
      } else if (zoom > MIN_RES_7_ZOOM) {
        return H3_RES7_LEVEL_LAYER;
      }
    }

    return this.activeLevel.getValue();
  }

  public getSufficientZoomByLevel(level: string): number {
    switch (level) {
      case 'STATE':
      case STATE_LEVEL_LAYER:
        return 5;

      case 'COUNTY':
      case COUNTY_LEVEL_LAYER:
        return 7;

      case 'H3_RES_5':
      case H3_RES5_LEVEL_LAYER:
        return 10;

      case 'H3_RES_7':
      case H3_RES7_LEVEL_LAYER:
        return 13;

      default:
        console.warn('getSufficientZoomByLevel, no case for: ', level);
        return 10;
    }
  }

  public isCurrentLevelCells(): boolean {
    return (
      this.activeLevel.getValue() !== STATE_LEVEL_LAYER &&
      this.activeLevel.getValue() !== COUNTY_LEVEL_LAYER
    );
  }

  public handleLevelChange(level: string): void {
    this.activeLevel.next(level);
  }

  public handleLayerChange(layer: string): void {
    this.activeLayer.next(layer);
  }

  private handleLayerLevelSubscription(): void {
    // Subscribe to latest values of both activeLevel and activeLayer
    this.activeLevel
      .pipe(combineLatestWith(this.activeLayer), debounceTime(350))
      .subscribe(([level, layer]) => {
        // If current combination inaccessible do some logic like changing levels, showing notifications etc
        if (this.isLayerLevelCombinationUnavailable(level, layer)) {
          // On level change show notification and force change layer to accessible one
          if (level !== this.previousActiveLevel) {
            const accessibleLevel = this.getAccessibleLevelForLayer(layer);
            if (accessibleLevel && level !== accessibleLevel) {
              this.handleLevelChange(accessibleLevel);
              this.currentLayerForceChangeStage =
                LevelLayerComboCorrectionStages.NOTIFICATION_SHOWN;
              if (this.toast.currentlyActive < 1) {
                this.toast.warning(
                  '', // Empty title
                  'You have been automatically switched to the County level to view data for the selected parameter'
                );
              }
            }
          }

          // On layer change show modal
          if (layer !== this.previousActiveLayer) {
            this.modalService.openModal(dataIsNotAvailableForLevelText);
            this.currentLayerForceChangeStage =
              LevelLayerComboCorrectionStages.MODAL_SHOWN;
          }
        }

        this.previousActiveLevel = level;
        this.previousActiveLayer = layer;
      });
  }

  public isLayerLevelCombinationUnavailable(
    level: string,
    layer: string
  ): boolean {
    if (
      voteDataFeatures.includes(layer) &&
      (level === H3_RES7_LEVEL_LAYER || level === H3_RES5_LEVEL_LAYER)
    ) {
      return true;
    } else if (crimeRatesList.includes(layer) && level !== COUNTY_LEVEL_LAYER) {
      return true;
    }
    return false;
  }

  private getAccessibleLevelForLayer(
    layer: string
  ):
    | typeof COUNTY_LEVEL_LAYER
    | typeof STATE_LEVEL_LAYER
    | typeof H3_RES5_LEVEL_LAYER
    | typeof H3_RES7_LEVEL_LAYER
    | null {
    // Set up base level for different layers
    if (voteDataFeatures.includes(layer)) {
      return COUNTY_LEVEL_LAYER;
    } else if (crimeRatesList.includes(layer)) {
      return COUNTY_LEVEL_LAYER;
    }

    return null;
  }
}
