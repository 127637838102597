import { Injectable } from '@angular/core';
import { MapModalData } from '../../user/map-redirect-modal/map-redirect-modal.component';
import { SubscriptionPlans } from '../../user/user/user.model';
import { LANDSCAPE_MOBILE } from './device-orientation.service';
import { MatDialog } from '@angular/material/dialog';
import { modalRegistry } from './modal-registry';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public isAIDescriptionModalOpened = false;
  public isModalOpen = false;

  constructor(private dialog: MatDialog) {}

  //TODO: Extend this service to open other modals too
  public async openModal(text: MapModalData): Promise<void> {
    // Check if modal is already opened otherwise it could open in multiple instances
    if (!this.isModalOpen) {
      this.isModalOpen = true; // Set the status to open
      const dialogRef = this.dialog.open(
        await modalRegistry.MapRedirectModal(),
        {
          panelClass: 'custom-dialog-container',
          disableClose: text.disableClose,
          hasBackdrop: text.hasBackdrop,
          maxWidth: '620px',
          data: text,
        }
      );

      dialogRef.afterClosed().subscribe(() => {
        this.isModalOpen = false;
      });
    }
  }

  public async openCellAIDescriptionModal(
    geoId: string,
    level: string,
    title: string
  ): Promise<void> {
    if (!this.isAIDescriptionModalOpened) {
      const dialogRef = this.dialog.open(
        await modalRegistry.AiDescriptionModal(),
        {
          panelClass: 'custom-dialog-container',
          disableClose: false,
          hasBackdrop: false,
          maxWidth: '620px',
          data: { geoId, level, title },
        }
      );

      dialogRef.afterClosed().subscribe(() => {
        this.isAIDescriptionModalOpened = false;
      });

      dialogRef.afterOpened().subscribe(() => {
        this.isAIDescriptionModalOpened = true;
      });
    }
  }

  public closeCellAiDescriptionModal(): void {
    if (this.isAIDescriptionModalOpened) {
      this.dialog.closeAll();
    }
  }
  // public handleLimitedAccessModal(focusedFeatures: FocusedFeatures, zoom: number): void {
  //   const accessibleFeatures = this.subscriptionsService.getAccessibleFeatures().features;
  //   const focusedFeaturesArray = focusedFeatures.getFeatures();
  //
  //   const existsInFocusedFeatures = accessibleFeatures.some(
  //     accessibleFeature => focusedFeaturesArray.find(
  //       focusedFeature => focusedFeature.properties!.external_id === accessibleFeature
  //     )
  //   );
  //
  //   if (!existsInFocusedFeatures || zoom < 9) {
  //     this.openModal(moveTrialUserToAtlantaText)
  //   }
  // }

  public async handleSubscriptionConfirmationModal(
    plan: SubscriptionPlans,
    isPeriodMonthly: boolean,
    freePeriodString: string | null,
    promoCode: string | null
  ): Promise<void> {
    this.dialog.open(await modalRegistry.SubscriptionConfirmationModal(), {
      panelClass: 'custom-dialog-container',
      disableClose: false,
      maxWidth: '620px',
      data: {
        plan: plan,
        isPeriodMonthly: isPeriodMonthly,
        freePeriodString: freePeriodString,
        promoCode: promoCode,
      },
    });
  }

  public async handleSubscriptionRenewalModal(
    isAutoRenewable: boolean
  ): Promise<void> {
    this.dialog.open(await modalRegistry.SubscriptionRenewalModal(), {
      panelClass: 'custom-dialog-container',
      disableClose: false,
      maxWidth: '620px',
      data: {
        isAutoRenewable: isAutoRenewable,
      },
    });
  }

  public async handleSwitchLandscapeModal(orientation: string): Promise<void> {
    if (orientation === LANDSCAPE_MOBILE) {
      this.dialog.open(await modalRegistry.SwitchLandscapeModal(), {
        panelClass: 'custom-dialog-container',
        disableClose: false,
        maxWidth: '620px',
        maxHeight: '180px',
        data: {
          title: 'Rotate Your Device',
          text: 'Please rotate your device to portrait mode',
          closable: true,
        },
      });
    }
  }

  public async handleFavoriteCellPersonalizationModal(
    id: string,
    title: string,
    level: string
  ): Promise<void> {
    this.dialog.open(await modalRegistry.FavoriteCellPersonalizationModal(), {
      panelClass: 'custom-dialog-container',
      disableClose: false,
      maxWidth: '620px',
      maxHeight: '510px',
      data: { id, title: title, level: level },
    });
  }
}
